//  TODO split to different files based on context
export type Profile = {
  id: string;
  name: string;
  username: string;
  avatar_url: string | null;
};

export type ProfileResponse = Profile;

export type ContactsResponse = {
  next_token: string;
  profiles: Profile[];
};

export type CreateRoomResponse = {
  payment_id: string;
};

export type RoomType = "video" | "audio" | "streamed";
export type RoomStatus = "processing" | "ready" | "ready_to_renew" | "expired";

export type Room = {
  id: string;
  expiration_datetime: string;
  max_duration_in_sec: number;
  max_participants: number;
  name: string;
  owner_id: string;
  private_url: string;
  room_type: RoomType;
  status: RoomStatus;
  subscription_limit_in_seconds: number | null;
  subscription_limit_used_in_seconds: number | null;
};

export type RoomDetails = {
  id: string;
  expiration_datetime: string;
  host_required: boolean;
  max_duration_in_sec: number;
  max_participants: number;
  name: string;
  owner_id: string;
  offer_type_id: string;
  private_url: string;
  waiting_room: boolean;
  room_type: RoomType;
  status: RoomStatus;
  subscription_limit_in_seconds: number | null;
  subscription_limit_used_in_seconds: number | null;
};

export type RoomsResponse = {
  rooms: Room[];
};

export type ActiveSession = {
  participants_count: number;
  room_id: string;
  users_profiles: Profile[];
};

export type RoomActiveSessionResponse = {
  session: ActiveSession;
};

export type ActiveSessionsResponse = {
  sessions: ActiveSession[];
};

export type RoomDetailsResponse = RoomDetails;

export const CURRENCY_SETTINGS = {
  NGN: { name: "Naira", symbol: "₦" },
  GBP: {
    name: "Pound sterling",
    symbol: "£",
  },
  USD: {
    name: "United States dollar",
    symbol: "$",
  },
} as const;

export type CurrencySettings = {
  [K in keyof typeof CURRENCY_SETTINGS]: {
    name: (typeof CURRENCY_SETTINGS)[K]["name"];
    code: K;
    symbol: (typeof CURRENCY_SETTINGS)[K]["symbol"];
  };
}[keyof typeof CURRENCY_SETTINGS];

export type CurrencyCode = CurrencySettings["code"];

type SubscriptionType = "daily" | "monthly" | "monthly_limited" | "annual";

export type Offer = {
  currency: CurrencyCode;
  id: string;
  percentage_discount: number;
  price: number;
  price_before_discount: number;
  subscription_limit_in_hours: number;
  subscription_type: SubscriptionType;
};

export type OfferCode =
  | "VIDEO_ROOM"
  | "BIG_VIDEO_ROOM"
  | "AUDIO_ROOM"
  | "BIG_AUDIO_ROOM";

export type OfferType = {
  max_duration_in_sec: number;
  max_participants: number;
  name: string;
  offer_code: OfferCode;
  offer_type_id: string;
  room_type: RoomType;
  offers: Offer[];
};

export type OffersResponse = {
  offers_summaries: OfferType[];
};

export type CheckPaymentResponse =
  | {
      payment_status: "FAILED" | "PROCESSING";
    }
  | {
      is_new_room_purchase: boolean;
      payment_status: "PAID";
      room_id: string;
    };

export type CalendarEvent = {
  id: string;
  meeting_id: string;
  start_datetime: string;
  end_datetime: string;
};

export type MeetingRepeatability = "none" | "daily" | "weekly";

export type Meeting = {
  id: string;
  name: string;
  owner_id: string;
  repeatability: MeetingRepeatability;
  room_id: string;
  room_type: RoomType;
  room_name: string;
  room_private_url: string;
};

export type MeetingCreateResponse = {
  meeting: Meeting;
};

export type CalendarEventsResponse = {
  calendar_events: CalendarEvent[];
  meetings: Meeting[];
  users_profiles: Profile[];
  next_token: string;
};

export type TodayCalendarEventsResponse = {
  calendar_events: CalendarEvent[];
  meetings: Meeting[];
  users_profiles: Profile[];
};

export type MeetingPayload = {
  name: string;
  repeatability: MeetingRepeatability;
  room_id: string;
  start_day: string;
  start_time: string;
  duration_in_sec: number;
  invited_users_ids: string[];
};

export type JoinRoomResponse = {
  access_key: string;
  channel: string;
  join_type: "room" | "waiting_room";
  session_id?: string;
};
